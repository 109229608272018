import axios from "axios";
import authHeader from "./auth-header";
const NODE_API_URL = process.env.REACT_APP_NODE_API_URL;
const API_URL = process.env.REACT_APP_BACKEND_URL;
const FRONTEND_URL = process.env.REACT_APP_FRONTEND_URL;
const REACT_APP_GOOGLE_KEY = process.env.REACT_APP_GOOGLE_KEY;

const login = (data) => {
  axios.interceptors.request.use(
    function (req) {
      return req;
    },
    (err) => {
      return Promise.reject(err);
    }
  );

  axios.interceptors.response.use(
    function (res) {
      return res;
    },
    (err) => {
      return Promise.reject(err);
    }
  );

  let header = authHeader();
  header["Content-Type"] = "application/json";
  return (
    axios
      .post(API_URL + "/auth/keycloak/callback?access_token=" + data, {
        headers: header,
      })
      // .post(API_URL + "/auth/local", data, { headers: header })
      .then((response) => {
        if (response.data.jwt) {
          localStorage.setItem("session", JSON.stringify(response.data));
        }
        return response.data;
      })
  );
};

/*const logout = () => {
  localStorage.removeItem("session");
  localStorage.removeItem("userCollab");
  localStorage.removeItem("react-token");
  localStorage.removeItem("react-refresh-token");
  window.location.href = FRONTEND_URL;
};*/

const createEvent = (data, type) => {
  axios.interceptors.request.use(
    function (req) {
      return req;
    },
    (err) => {
      return Promise.reject(err);
    }
  );

  axios.interceptors.response.use(
    function (res) {
      return res;
    },
    (err) => {
      return Promise.reject(err);
    }
  );

  let header = authHeader();
  header["Content-Type"] = "application/json";
  if (type === "master") {
    return axios
      .post(API_URL + "/master-events", data, { headers: header })
      .then((response) => {
        return response.data;
      });
  } else {
    return axios
      .post(API_URL + "/events", data, { headers: header })
      .then((response) => {
        return response.data;
      });
  }
};

const editEvent = (data, eventType = "others", eventId) => {
  axios.interceptors.request.use(
    function (req) {
      return req;
    },
    (err) => {
      return Promise.reject(err);
    }
  );

  axios.interceptors.response.use(
    function (res) {
      return res;
    },
    (err) => {
      return Promise.reject(err);
    }
  );

  let header = authHeader();
  header["Content-Type"] = "application/json";
  if (eventType === "master") {
    return axios
      .put(API_URL + "/master-events/" + eventId, data, { headers: header })
      .then((response) => {
        return response.data;
      });
  } else {
    return axios
      .put(API_URL + "/events/" + eventId, data, { headers: header })
      .then((response) => {
        return response.data;
      });
  }
};

const createUser = (data, id, action) => {
  axios.interceptors.request.use(
    function (req) {
      return req;
    },
    (err) => {
      return Promise.reject(err);
    }
  );

  axios.interceptors.response.use(
    function (res) {
      return res;
    },
    (err) => {
      return Promise.reject(err);
    }
  );

  let header = authHeader();
  header["Content-Type"] = "application/json";
  if (action === "EDIT") {
    return axios
      .put(API_URL + "/users/" + id, data, { headers: header })
      .then((response) => {
        return response.data;
      });
  } else if (action === "DELETE") {
    return axios
      .delete(API_URL + "/users/" + id, { headers: header })
      .then((response) => {
        return response.data;
      });
  } else {
    return axios
      .post(API_URL + "/users", data, { headers: header })
      .then((response) => {
        return response.data;
      });
  }
};

const createCollaborator = (data, id, action) => {
  axios.interceptors.request.use(
    function (req) {
      return req;
    },
    (err) => {
      return Promise.reject(err);
    }
  );

  axios.interceptors.response.use(
    function (res) {
      return res;
    },
    (err) => {
      return Promise.reject(err);
    }
  );

  let header = authHeader();
  header["Content-Type"] = "application/json";
  if (action === "EDIT") {
    return axios
      .put(API_URL + "/collaborators/" + id, data, { headers: header })
      .then((response) => {
        return response.data;
      });
  } else if (action === "DELETE") {
    return axios
      .delete(API_URL + "/collaborators/" + id, { headers: header })
      .then((response) => {
        return response.data;
      });
  } else {
    return axios
      .post(API_URL + "/collaborators", data, { headers: header })
      .then((response) => {
        return response.data;
      });
  }
};

const createShowcases = (data) => {
  axios.interceptors.request.use(
    function (req) {
      return req;
    },
    (err) => {
      return Promise.reject(err);
    }
  );

  axios.interceptors.response.use(
    function (res) {
      return res;
    },
    (err) => {
      return Promise.reject(err);
    }
  );

  let header = authHeader();
  header["Content-Type"] = "application/json";
  return axios
    .post(API_URL + "/showcases", data, { headers: header })
    .then((response) => {
      return response.data;
    });
};

const updateShowcases = (id, data, action) => {
  axios.interceptors.request.use(
    function (req) {
      return req;
    },
    (err) => {
      return Promise.reject(err);
    }
  );

  axios.interceptors.response.use(
    function (res) {
      return res;
    },
    (err) => {
      return Promise.reject(err);
    }
  );

  let header = authHeader();
  header["Content-Type"] = "application/json";
  if (action === "EDIT") {
    return axios
      .put(API_URL + "/showcases/" + id, data, { headers: header })
      .then((response) => {
        return response.data;
      });
  } else if (action === "DELETE") {
    return axios
      .delete(API_URL + "/showcases/" + id, { headers: header })
      .then((response) => {
        return response.data;
      });
  }
};

const areaActions = (id, data, action) => {
  axios.interceptors.request.use(
    function (req) {
      return req;
    },
    (err) => {
      return Promise.reject(err);
    }
  );

  axios.interceptors.response.use(
    function (res) {
      return res;
    },
    (err) => {
      return Promise.reject(err);
    }
  );

  let header = authHeader();
  header["Content-Type"] = "application/json";
  if (action === "ADD") {
    return axios
      .post(API_URL + "/areas", data, { headers: header })
      .then((response) => {
        return response.data;
      });
  } else if (action === "EDIT") {
    return axios
      .put(API_URL + "/areas/" + id, data, { headers: header })
      .then((response) => {
        return response.data;
      });
  } else if (action === "DELETE") {
    return axios
      .delete(API_URL + "/areas/" + id, { headers: header })
      .then((response) => {
        return response.data;
      });
  }
};

const MediaTagActions = (id, data, action) => {
  axios.interceptors.request.use(
    function (req) {
      return req;
    },
    (err) => {
      return Promise.reject(err);
    }
  );

  axios.interceptors.response.use(
    function (res) {
      return res;
    },
    (err) => {
      return Promise.reject(err);
    }
  );

  let header = authHeader();
  header["Content-Type"] = "application/json";
  if (action === "ADD") {
    return axios
      .post(API_URL + "/media-tags", data, { headers: header })
      .then((response) => {
        return response.data;
      });
  } else if (action === "EDIT") {
    return axios
      .put(API_URL + "/media-tags/" + id, data, { headers: header })
      .then((response) => {
        return response.data;
      });
  } else if (action === "DELETE") {
    return axios
      .delete(API_URL + "/media-tags/" + id, { headers: header })
      .then((response) => {
        return response.data;
      });
  }
};

const updateEvent = (eventId, data) => {
  axios.interceptors.request.use(
    function (req) {
      return req;
    },
    (err) => {
      return Promise.reject(err);
    }
  );

  axios.interceptors.response.use(
    function (res) {
      return res;
    },
    (err) => {
      return Promise.reject(err);
    }
  );

  let header = authHeader();
  header["Content-Type"] = "application/json";
  return axios
    .put(API_URL + "/events/" + eventId, data, { headers: header })
    .then((response) => {
      return response.data;
    });
};

const googleTextToSpeech = (data) => {
  axios.interceptors.request.use(
    function (req) {
      return req;
    },
    (err) => {
      return Promise.reject(err);
    }
  );

  axios.interceptors.response.use(
    function (res) {
      return res;
    },
    (err) => {
      return Promise.reject(err);
    }
  );

  let header = authHeader();
  header["Content-Type"] = "application/json";
  return axios
    .post(
      "https://texttospeech.googleapis.com/v1/text:synthesize?key=" +
        REACT_APP_GOOGLE_KEY,
      data
    )
    .then((response) => {
      return response.data;
    });
};

const uploadMedia = (data) => {
  axios.interceptors.request.use(
    function (req) {
      return req;
    },
    (err) => {
      return Promise.reject(err);
    }
  );

  axios.interceptors.response.use(
    function (res) {
      return res;
    },
    (err) => {
      return Promise.reject(err);
    }
  );

  let header = authHeader();
  header["Content-Type"] = "multipart/form-data;";
  return axios
    .post(API_URL + "/upload", data, { headers: header })
    .then((response) => {
      return response.data;
    });
};

const sendEmail = (data) => {
  axios.interceptors.request.use(
    function (req) {
      return req;
    },
    (err) => {
      return Promise.reject(err);
    }
  );

  axios.interceptors.response.use(
    function (res) {
      return res;
    },
    (err) => {
      return Promise.reject(err);
    }
  );

  let header = authHeader();
  header["Content-Type"] = "application/json";
  return axios
    .post(API_URL + "/email", data, { headers: header })
    .then((response) => {
      return response.data;
    });
};

const deleteMedia = (data) => {
  axios.interceptors.request.use(
    function (req) {
      return req;
    },
    (err) => {
      return Promise.reject(err);
    }
  );

  axios.interceptors.response.use(
    function (res) {
      return res;
    },
    (err) => {
      return Promise.reject(err);
    }
  );

  let header = authHeader();
  header["Content-Type"] = "application/json";
  return axios
    .delete(API_URL + "/upload/files/" + data, { headers: authHeader() })
    .then((response) => {
      return response.data;
    });
};

const eventAccesActions = (data) => {
  axios.interceptors.request.use(
    function (req) {
      return req;
    },
    (err) => {
      return Promise.reject(err);
    }
  );

  axios.interceptors.response.use(
    function (res) {
      return res;
    },
    (err) => {
      return Promise.reject(err);
    }
  );

  return axios.post(NODE_API_URL + "/api/admin_cli", data).then((response) => {
    return response;
  });
};

const addEditIndustryZone = (data, zoneType, zoneId) => {
  axios.interceptors.request.use(
    function (req) {
      return req;
    },
    (err) => {
      return Promise.reject(err);
    }
  );

  axios.interceptors.response.use(
    function (res) {
      return res;
    },
    (err) => {
      return Promise.reject(err);
    }
  );

  let header = authHeader();
  header["Content-Type"] = "application/json";
  if (zoneType === "add") {
    return axios
      .post(API_URL + "/industry-zones", data, { headers: header })
      .then((response) => {
        return response;
      });
  } else if (zoneType === "edit") {
    return axios
      .put(API_URL + "/industry-zones/" + zoneId, data, {
        headers: header,
      })
      .then((response) => {
        return response;
      });
  }
};

const createQuestion = (data) => {
  axios.interceptors.request.use(
    function (req) {
      return req;
    },
    (err) => {
      return Promise.reject(err);
    }
  );

  axios.interceptors.response.use(
    function (res) {
      return res;
    },
    (err) => {
      return Promise.reject(err);
    }
  );

  return axios.post(API_URL + "/quiz-questions",data, {
    headers: authHeader(),
  }).then((response) => {
    return response;
  });
}

export default {
  login,
  // logout,
  createEvent,
  editEvent,
  createUser,
  createCollaborator,
  createShowcases,
  updateShowcases,
  areaActions,
  MediaTagActions,
  updateEvent,
  uploadMedia,
  deleteMedia,
  sendEmail,
  googleTextToSpeech,
  eventAccesActions,
  addEditIndustryZone,
  createQuestion,
};
