import {
  SET_MESSAGE,
  LOGIN_SUCCESS,
  LOGIN_FAIL,
  LOGOUT,
  CREATE_EVENT_SUCCESS,
  CREATE_EVENT_FAIL,
  CREATE_USER_SUCCESS,
  CREATE_USER_FAIL,
  CREATE_COLLABRATOR_SUCCESS,
  CREATE_COLLABRATOR_FAIL,
  CREATE_SHOWCASES_SUCCESS,
  CREATE_SHOWCASES_FAIL,
  CREATE_AREA_SUCCESS,
  CREATE_AREA_FAIL,
  EDIT_EVENT_SUCCESS,
  EDIT_EVENT_FAIL,
  CREATE_EDITEVENT_SUCCESS,
  CREATE_EDITEVENT_FAIL,
  UPLOAD_MEDIA_SUCCESS,
  UPLOAD_MEDIA_FAIL,
  DELETE_MEDIA_SUCCESS,
  DELETE_MEDIA_FAIL,
  CREATE_MEDIATAG_SUCCESS,
  CREATE_MEDIATAG_FAIL,
  TEXTTOSPEECH_SUCCESS,
  TEXTTOSPEECH_FAIL,
  SEND_MAIL_SUCCESS,
  SEND_MAIL_FAIL,
  CREATE_EVENT_ACCESS_SUCCESS,
  CREATE_EVENT_ACCESS_FAIL,
  CREATE_EDIT_INDUSTRYZONE_SUCCESS,
  CREATE_EDIT_INDUSTRYZONE_FAIL,
  CREATE_QUESTION_SUCCESS,
  CREATE_QUESTION_FAIL,
} from "./types";

import AuthService from "../services/auth.service";
import UserService from "../services/user.service";

export const login = (data) => (dispatch) => {
  return AuthService.login(data).then(
    (response) => {
      UserService.getCollaboratorByEmail(data.identifier.toLowerCase()).then(
        (response1) => {
          dispatch({
            type: LOGIN_SUCCESS,
            payload: { session: response, userCollab: response1 },
          });
          return Promise.resolve();
        },
        (error) => {
          dispatch({
            type: LOGIN_FAIL,
          });
          dispatch({
            type: SET_MESSAGE,
          });
          return Promise.reject();
        }
      );
    },
    (error) => {
      dispatch({
        type: LOGIN_FAIL,
      });
      dispatch({
        type: SET_MESSAGE,
      });
      return Promise.reject();
    }
  );
};

export const logout = () => (dispatch) => {
  AuthService.logout();
  dispatch({
    type: LOGOUT,
  });
};

export const createEvent = (data, type) => (dispatch) => {
  return AuthService.createEvent(data, type).then(
    (response) => {
      dispatch({
        type: CREATE_EVENT_SUCCESS,
        payload: { createEventData: response },
      });
      dispatch({
        type: SET_MESSAGE,
      });
      return Promise.resolve();
    },
    (error) => {
      dispatch({
        type: CREATE_EVENT_FAIL,
      });
      dispatch({
        type: SET_MESSAGE,
      });
      return Promise.reject();
    }
  );
};

export const editEvent = (data, eventType, eventId) => (dispatch) => {
  return AuthService.editEvent(data, eventType, eventId).then(
    (response) => {
      dispatch({
        type: EDIT_EVENT_SUCCESS,
        payload: { editEventData: response },
      });
      dispatch({
        type: SET_MESSAGE,
      });
      return Promise.resolve();
    },
    (error) => {
      dispatch({
        type: EDIT_EVENT_FAIL,
      });
      dispatch({
        type: SET_MESSAGE,
      });
      return Promise.reject();
    }
  );
};

export const createUser = (data, id, action) => (dispatch) => {
  return AuthService.createUser(data, id, action).then(
    (response) => {
      dispatch({
        type: CREATE_USER_SUCCESS,
        payload: { createUserData: response },
      });
      dispatch({
        type: SET_MESSAGE,
        payload: { response },
      });
      return Promise.resolve();
    },
    (error) => {
      dispatch({
        type: CREATE_USER_FAIL,
        payload: { createUserError: error },
      });
      dispatch({
        type: SET_MESSAGE,
        payload: { error },
      });
      return Promise.reject();
    }
  );
};

export const createCollaborator = (data, id, action) => (dispatch) => {
  return AuthService.createCollaborator(data, id, action).then(
    (response) => {
      dispatch({
        type: CREATE_COLLABRATOR_SUCCESS,
        payload: { createClbData: response },
      });
      dispatch({
        type: SET_MESSAGE,
      });
      return Promise.resolve();
    },
    (error) => {
      dispatch({
        type: CREATE_COLLABRATOR_FAIL,
      });
      dispatch({
        type: SET_MESSAGE,
      });
      return Promise.reject();
    }
  );
};

export const createShowcases = (data) => (dispatch) => {
  return AuthService.createShowcases(data).then(
    (response) => {
      dispatch({
        type: CREATE_SHOWCASES_SUCCESS,
        payload: { createShowcaseData: response },
      });
      dispatch({
        type: SET_MESSAGE,
      });
      return Promise.resolve();
    },
    (error) => {
      dispatch({
        type: CREATE_SHOWCASES_FAIL,
      });
      dispatch({
        type: SET_MESSAGE,
      });
      return Promise.reject();
    }
  );
};

export const updateShowcases = (id, data, action) => (dispatch) => {
  return AuthService.updateShowcases(id, data, action).then(
    (response) => {
      dispatch({
        type: CREATE_SHOWCASES_SUCCESS,
        payload: { createShowcaseData: response },
      });
      dispatch({
        type: SET_MESSAGE,
      });
      return Promise.resolve();
    },
    (error) => {
      dispatch({
        type: CREATE_SHOWCASES_FAIL,
      });
      dispatch({
        type: SET_MESSAGE,
      });
      return Promise.reject();
    }
  );
};

export const areaActions = (id, data, action) => (dispatch) => {
  return AuthService.areaActions(id, data, action).then(
    (response) => {
      dispatch({
        type: CREATE_AREA_SUCCESS,
        payload: { createAreaData: response },
      });
      dispatch({
        type: SET_MESSAGE,
      });
      return Promise.resolve();
    },
    (error) => {
      dispatch({
        type: CREATE_AREA_FAIL,
      });
      dispatch({
        type: SET_MESSAGE,
      });
      return Promise.reject();
    }
  );
};

export const MediaTagActions = (id, data, action) => (dispatch) => {
  return AuthService.MediaTagActions(id, data, action).then(
    (response) => {
      dispatch({
        type: CREATE_MEDIATAG_SUCCESS,
        payload: { createAreaData: response },
      });
      dispatch({
        type: SET_MESSAGE,
      });
      return Promise.resolve();
    },
    (error) => {
      dispatch({
        type: CREATE_MEDIATAG_FAIL,
      });
      dispatch({
        type: SET_MESSAGE,
      });
      return Promise.reject();
    }
  );
};

export const updateEvent = (eventId, data) => (dispatch) => {
  return AuthService.updateEvent(eventId, data).then(
    (response) => {
      dispatch({
        type: CREATE_EDITEVENT_SUCCESS,
        payload: { createEditEventData: response },
      });
      dispatch({
        type: SET_MESSAGE,
      });
      return Promise.resolve();
    },
    (error) => {
      dispatch({
        type: CREATE_EDITEVENT_FAIL,
      });
      dispatch({
        type: SET_MESSAGE,
      });
      return Promise.reject();
    }
  );
};

export const uploadMedia = (data) => (dispatch) => {
  return AuthService.uploadMedia(data).then(
    (response) => {
      dispatch({
        type: UPLOAD_MEDIA_SUCCESS,
        payload: { uploadMediaData: response },
      });
      dispatch({
        type: SET_MESSAGE,
      });
      return Promise.resolve();
    },
    (error) => {
      dispatch({
        type: UPLOAD_MEDIA_FAIL,
        payload: { uploadMediaData: error },
      });
      dispatch({
        type: SET_MESSAGE,
      });
      return Promise.reject();
    }
  );
};

export const deleteMedia = (data) => (dispatch) => {
  return AuthService.deleteMedia(data).then(
    (response) => {
      dispatch({
        type: DELETE_MEDIA_SUCCESS,
        payload: { deleteMediaData: response },
      });
      dispatch({
        type: SET_MESSAGE,
      });
      return Promise.resolve();
    },
    (error) => {
      dispatch({
        type: DELETE_MEDIA_FAIL,
      });
      dispatch({
        type: SET_MESSAGE,
      });
      return Promise.reject();
    }
  );
};

export const googleTextToSpeech = (data) => (dispatch) => {
  return AuthService.googleTextToSpeech(data).then(
    (response) => {
      dispatch({
        type: TEXTTOSPEECH_SUCCESS,
        payload: { textToSpeechMedia: response },
      });
      dispatch({
        type: SET_MESSAGE,
      });
      return Promise.resolve();
    },
    (error) => {
      dispatch({
        type: TEXTTOSPEECH_FAIL,
      });
      dispatch({
        type: SET_MESSAGE,
      });
      return Promise.reject();
    }
  );
};

export const sendEmail = (data) => (dispatch) => {
  return AuthService.sendEmail(data).then(
    (response) => {
      dispatch({
        type: SEND_MAIL_SUCCESS,
        payload: { sendEmailData: response },
      });
      dispatch({
        type: SET_MESSAGE,
      });
      return Promise.resolve();
    },
    (error) => {
      dispatch({
        type: SEND_MAIL_FAIL,
      });
      dispatch({
        type: SET_MESSAGE,
      });
      return Promise.reject();
    }
  );
};

export const eventAccesActions = (data) => (dispatch) => {
  return AuthService.eventAccesActions(data).then(
    (response) => {
      dispatch({
        type: CREATE_EVENT_ACCESS_SUCCESS,
        payload: { createEventAccessData: response.data },
      });
      dispatch({
        type: SET_MESSAGE,
      });
      return Promise.resolve();
    },
    (error) => {
      dispatch({
        type: CREATE_EVENT_ACCESS_FAIL,
      });
      dispatch({
        type: SET_MESSAGE,
      });
      return Promise.reject();
    }
  );
};

export const addEditIndustryZone = (data, zoneType, zoneId) => (dispatch) => {
  return AuthService.addEditIndustryZone(data, zoneType, zoneId).then(
    (response) => {
      dispatch({
        type: CREATE_EDIT_INDUSTRYZONE_SUCCESS,
        payload: { createEditIndustryZoneData: response.data },
      });
      dispatch({
        type: SET_MESSAGE,
      });
      return Promise.resolve();
    },
    (error) => {
      dispatch({
        type: CREATE_EDIT_INDUSTRYZONE_FAIL,
      });
      dispatch({
        type: SET_MESSAGE,
      });
      return Promise.reject();
    }
  );
};

export const createQuestion = (data) => (dispatch) => {
  return AuthService.createQuestion(data).then(
    (response) => {
      dispatch({
        type: CREATE_QUESTION_SUCCESS,
        payload: { createQuestionData: response.data },
      });
      dispatch({
        type: SET_MESSAGE,
      });
      return Promise.resolve();
    },
    (error) => {
      dispatch({
        type: CREATE_QUESTION_FAIL,
      });
      dispatch({
        type: SET_MESSAGE,
      });
      return Promise.reject();
    }
  );
}
