import { ErrorMessage } from "@hookform/error-message";
import {
  Button,
  FormControl,
  FormGroup,
  InputLabel,
  OutlinedInput,
} from "@material-ui/core";
import React from "react";
import { Col, Modal, Row } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { statusErrorSuccess } from "../../App";
import { createQuestion } from "../../actions/auth";

const CreateQuestionPopup = (props) => {
  const dispatch = useDispatch();
  const [formInputs, setFormInputs] = React.useState({
    question: "",
    option_a: "",
    option_b: "",
    option_c: "",
    option_d: "",
    answer: "",
  });

  function isOnlySpaces(str) {
    return str.trim().length === 0;
  }

  const handleDone = async () => {
    const options = [];
    if (formInputs.option_a !== "") options.push(formInputs.option_a);
    if (formInputs.option_b !== "") options.push(formInputs.option_b);
    if (formInputs.option_c !== "") options.push(formInputs.option_c);
    if (formInputs.option_d !== "") options.push(formInputs.option_d);
    if (options.length < 2) {
      statusErrorSuccess("provide_atleast_2_options", "danger");
      return;
    } else if (formInputs.question === "" || formInputs.answer === "") {
      statusErrorSuccess("provide_question_and_answer", "danger");
      return;
    }else if(isOnlySpaces(formInputs.question) || isOnlySpaces(formInputs.answer) || isOnlySpaces(formInputs.option_a) || isOnlySpaces(formInputs.option_b)){
      statusErrorSuccess("provide_valid_input", "danger");
      return;

    }else if(formInputs.answer !== formInputs.option_a && formInputs.answer !== formInputs.option_b && formInputs.answer !== formInputs.option_c && formInputs.answer !== formInputs.option_d){
      statusErrorSuccess("answer_must_be_one_of_the_options", "danger");
      return

    }
     else {
      const data = {
        question: formInputs.question,
        answer: formInputs.answer,
        options: options,
      };
      dispatch(createQuestion(data))
        .then((res) => {
            props.onHide();
          statusErrorSuccess("question_created", "success");
        })
        .catch((err) => {
          statusErrorSuccess("something_went_wrong", "danger");
        });
    }
  };

  return (
    <Modal
      show={props.show}
      onHide={props.onHide}
      size="xl"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="ad-area-modal upload-assets-popup for_media_library"
    >
      <Modal.Header>
        <div>
          <h4 className="popup_title">
            Add a new question to the questions library
          </h4>
          <p className="selected_assets_caption">
            Manage the questions before adding them to the Questions Library
          </p>
        </div>
        <div className="d-flex align-items-center justify-content-between">
          <p className="popup_close_icon" onClick={props.onHide}>
            <img className="img-fluid" src="/images/close.svg" alt="" />
          </p>
        </div>
      </Modal.Header>
      <Modal.Body>
        <Row>
          <Col lg={12} md={12} sm={12}>
            <FormGroup className="material-group">
              <FormControl variant="outlined" className="cover_with_button">
                <InputLabel htmlFor="voiceOverText" className="label-caption">
                  Question
                </InputLabel>
                <OutlinedInput
                  id="question"
                  type="text"
                  multiline
                  value={formInputs.question}
                  onChange={(e) =>
                    setFormInputs({ ...formInputs, question: e.target.value })
                  }
                  rows={3}
                  name="question"
                  className="material"
                />
              </FormControl>
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col lg={6} md={6} sm={6}>
            <FormGroup className="material-group">
              <FormControl variant="outlined" className="cover_with_button">
                <InputLabel htmlFor="voiceOverText" className="label-caption">
                  Option A
                </InputLabel>
                <OutlinedInput
                  id="option_a"
                  type="text"
                  multiline
                  value={formInputs.option_a}
                  onChange={(e) =>
                    setFormInputs({ ...formInputs, option_a: e.target.value })
                  }
                  rows={5}
                  name="option_a"
                  className="material"
                />
              </FormControl>
            </FormGroup>
          </Col>
          <Col lg={6} md={6} sm={6}>
            <FormGroup className="material-group">
              <FormControl variant="outlined" className="cover_with_button">
                <InputLabel htmlFor="voiceOverText" className="label-caption">
                  Option B
                </InputLabel>
                <OutlinedInput
                  id="option_b"
                  type="text"
                  multiline
                  value={formInputs.option_b}
                  onChange={(e) =>
                    setFormInputs({ ...formInputs, option_b: e.target.value })
                  }
                  rows={5}
                  name="option_b"
                  className="material"
                />
              </FormControl>
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col lg={6} md={6} sm={6}>
            <FormGroup className="material-group">
              <FormControl variant="outlined" className="cover_with_button">
                <InputLabel htmlFor="voiceOverText" className="label-caption">
                  Option C
                </InputLabel>
                <OutlinedInput
                  id="option_c"
                  type="text"
                  multiline
                  value={formInputs.option_c}
                  onChange={(e) =>
                    setFormInputs({ ...formInputs, option_c: e.target.value })
                  }
                  rows={5}
                  name="option_c"
                  className="material"
                />
              </FormControl>
            </FormGroup>
          </Col>
          <Col lg={6} md={6} sm={6}>
            <FormGroup className="material-group">
              <FormControl variant="outlined" className="cover_with_button">
                <InputLabel htmlFor="voiceOverText" className="label-caption">
                  Option D
                </InputLabel>
                <OutlinedInput
                  id="option_d"
                  type="text"
                  multiline
                  value={formInputs.option_d}
                  onChange={(e) =>
                    setFormInputs({ ...formInputs, option_d: e.target.value })
                  }
                  rows={5}
                  name="option_d"
                  className="material"
                />
              </FormControl>
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col lg={12} md={12} sm={12}>
            <FormGroup className="material-group">
              <FormControl variant="outlined" className="cover_with_button">
                <InputLabel htmlFor="voiceOverText" className="label-caption">
                  Answer
                </InputLabel>
                <OutlinedInput
                  id="answer"
                  type="text"
                  multiline
                  value={formInputs.answer}
                  onChange={(e) =>
                    setFormInputs({ ...formInputs, answer: e.target.value })
                  }
                  rows={3}
                  name="answer"
                  className="material"
                />
              </FormControl>
            </FormGroup>
          </Col>
        </Row>
        <div className="modal_footer flex_end_footer">
          <div className="upload_section">
            <Button
              variant="outlined"
              color="primary"
              className="remove_btn mr-2"
              onClick={props.onHide}
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              color="primary"
              onClick={() => handleDone()}
            >
              Done
            </Button>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default CreateQuestionPopup;
