import axios from "axios";
import authHeader from "./auth-header";
const API_URL = process.env.REACT_APP_BACKEND_URL;
const SORTING = "_sort=created_at:DESC";

const loginValidate = (data) => {
  axios.interceptors.request.use(
    function (req) {
      return req;
    },
    (err) => {
      return Promise.reject(err);
    }
  );

  axios.interceptors.response.use(
    function (res) {
      return res;
    },
    (err) => {
      return Promise.reject(err);
    }
  );

  return axios.get(API_URL + "/auth/keycloak/callback?access_token=" + data, {
    headers: authHeader(),
  });
};

const getMasterEventCard = () => {
  axios.interceptors.request.use(
    function (req) {
      return req;
    },
    (err) => {
      return Promise.reject(err);
    }
  );

  axios.interceptors.response.use(
    function (res) {
      return res;
    },
    (err) => {
      return Promise.reject(err);
    }
  );

  return axios.get(API_URL + "/master-events", { headers: authHeader() });
};

const getOtherEventCard = () => {
  axios.interceptors.request.use(
    function (req) {
      return req;
    },
    (err) => {
      return Promise.reject(err);
    }
  );

  axios.interceptors.response.use(
    function (res) {
      return res;
    },
    (err) => {
      return Promise.reject(err);
    }
  );

  return axios.get(API_URL + "/events?" + SORTING + "&_limit=-1", {
    headers: authHeader(),
  });
};

const getOtherEventCardByCollabId = (collabId) => {
  axios.interceptors.request.use(
    function (req) {
      return req;
    },
    (err) => {
      return Promise.reject(err);
    }
  );

  axios.interceptors.response.use(
    function (res) {
      return res;
    },
    (err) => {
      return Promise.reject(err);
    }
  );

  return axios.get(
    API_URL + "/events?collaborator_ids_contains=" + collabId + "&" + SORTING,
    { headers: authHeader() }
  );
};

const getExperiencesList = (accessId, type = "others") => {
  axios.interceptors.request.use(
    function (req) {
      return req;
    },
    (err) => {
      return Promise.reject(err);
    }
  );

  axios.interceptors.response.use(
    function (res) {
      return res;
    },
    (err) => {
      return Promise.reject(err);
    }
  );

  if (type === "master")
    return axios.get(API_URL + "/master-events/?access_id=" + accessId, {
      headers: authHeader(),
    });
  else
    return axios.get(API_URL + "/events/?access_id=" + accessId, {
      headers: authHeader(),
    });
};

const getAreaDetail = (areaId) => {
  axios.interceptors.request.use(
    function (req) {
      return req;
    },
    (err) => {
      return Promise.reject(err);
    }
  );

  axios.interceptors.response.use(
    function (res) {
      return res;
    },
    (err) => {
      return Promise.reject(err);
    }
  );

  return axios.get(API_URL + "/areas?area_id=" + areaId, {
    headers: authHeader(),
  });
};

const getPeopleList = () => {
  axios.interceptors.request.use(
    function (req) {
      return req;
    },
    (err) => {
      return Promise.reject(err);
    }
  );

  axios.interceptors.response.use(
    function (res) {
      return res;
    },
    (err) => {
      return Promise.reject(err);
    }
  );

  return axios.get(API_URL + "/users?" + SORTING + "&_limit=-1", {
    headers: authHeader(),
  });
};

const getPeopleById = (id) => {
  axios.interceptors.request.use(
    function (req) {
      return req;
    },
    (err) => {
      return Promise.reject(err);
    }
  );

  axios.interceptors.response.use(
    function (res) {
      return res;
    },
    (err) => {
      return Promise.reject(err);
    }
  );

  return axios.get(API_URL + "/users/" + id, { headers: authHeader() });
};
const getCollaboratorsList = () => {
  axios.interceptors.request.use(
    function (req) {
      return req;
    },
    (err) => {
      return Promise.reject(err);
    }
  );

  axios.interceptors.response.use(
    function (res) {
      return res;
    },
    (err) => {
      return Promise.reject(err);
    }
  );

  return axios.get(API_URL + "/collaborators?" + SORTING + "&_limit=-1", {
    headers: authHeader(),
  });
};

const getCollaboratorsById = (id) => {
  axios.interceptors.request.use(
    function (req) {
      return req;
    },
    (err) => {
      return Promise.reject(err);
    }
  );

  axios.interceptors.response.use(
    function (res) {
      return res;
    },
    (err) => {
      return Promise.reject(err);
    }
  );

  return axios.get(API_URL + "/collaborators/" + id, { headers: authHeader() });
};

const getCollaboratorDataByEmail = (email) => {
  axios.interceptors.request.use(
    function (req) {
      return req;
    },
    (err) => {
      return Promise.reject(err);
    }
  );

  axios.interceptors.response.use(
    function (res) {
      return res;
    },
    (err) => {
      return Promise.reject(err);
    }
  );

  return axios.get(API_URL + "/collaborators?email=" + email, {
    headers: authHeader(),
  });
};

const getCollaboratorByEmail = (email) => {
  axios.interceptors.request.use(
    function (req) {
      return req;
    },
    (err) => {
      return Promise.reject(err);
    }
  );

  axios.interceptors.response.use(
    function (res) {
      return res;
    },
    (err) => {
      return Promise.reject(err);
    }
  );

  return axios
    .get(API_URL + "/collaborators?email=" + email, { headers: authHeader() })
    .then((response) => {
      if (response.data.length > 0) {
        localStorage.setItem("userCollab", JSON.stringify(response.data));
      }
      return response.data;
    });
};

const getShowcaseList = () => {
  axios.interceptors.request.use(
    function (req) {
      return req;
    },
    (err) => {
      return Promise.reject(err);
    }
  );

  axios.interceptors.response.use(
    function (res) {
      return res;
    },
    (err) => {
      return Promise.reject(err);
    }
  );

  return axios.get(API_URL + "/showcases?" + SORTING + "&_limit=-1", {
    headers: authHeader(),
  });
};

const getAreaList = () => {
  axios.interceptors.request.use(
    function (req) {
      return req;
    },
    (err) => {
      return Promise.reject(err);
    }
  );

  axios.interceptors.response.use(
    function (res) {
      return res;
    },
    (err) => {
      return Promise.reject(err);
    }
  );

  return axios.get(API_URL + "/areas?" + SORTING + "&_limit=-1", {
    headers: authHeader(),
  });
};

const getMediaTages = () => {
  axios.interceptors.request.use(
    function (req) {
      return req;
    },
    (err) => {
      return Promise.reject(err);
    }
  );

  axios.interceptors.response.use(
    function (res) {
      return res;
    },
    (err) => {
      return Promise.reject(err);
    }
  );

  return axios.get(API_URL + "/media-tags?" + SORTING + "&_limit=-1", {
    headers: authHeader(),
  });
};

const getAreaById = (id) => {
  axios.interceptors.request.use(
    function (req) {
      return req;
    },
    (err) => {
      return Promise.reject(err);
    }
  );

  axios.interceptors.response.use(
    function (res) {
      return res;
    },
    (err) => {
      return Promise.reject(err);
    }
  );

  return axios.get(API_URL + "/areas?area_id=" + id, { headers: authHeader() });
};

const getEventSetting = (id, type = "others") => {
  axios.interceptors.request.use(
    function (req) {
      return req;
    },
    (err) => {
      return Promise.reject(err);
    }
  );

  axios.interceptors.response.use(
    function (res) {
      return res;
    },
    (err) => {
      return Promise.reject(err);
    }
  );

  if (type === "master")
    return axios.get(API_URL + "/master-events/" + id, {
      headers: authHeader(),
    });
  else return axios.get(API_URL + "/events/" + id, { headers: authHeader() });
};

const getMediaFiles = (id, sorting = "_sort=created_at:DESC") => {
  axios.interceptors.request.use(
    function (req) {
      return req;
    },
    (err) => {
      return Promise.reject(err);
    }
  );

  axios.interceptors.response.use(
    function (res) {
      return res;
    },
    (err) => {
      return Promise.reject(err);
    }
  );

  return axios.get(API_URL + "/upload/files?" + sorting + "&_limit=-1", {
    headers: authHeader(),
  });
};

const getIndustryZoneList = () => {
  axios.interceptors.request.use(
    function (req) {
      return req;
    },
    (err) => {
      return Promise.reject(err);
    }
  );

  axios.interceptors.response.use(
    function (res) {
      return res;
    },
    (err) => {
      return Promise.reject(err);
    }
  );

  return axios.get(API_URL + "/industry-zones", { headers: authHeader() });
};

const getQuestionList = (sorting = "_sort=created_at:DESC") => {
  axios.interceptors.request.use(
    function (req) {
      return req;
    },
    (err) => {
      return Promise.reject(err);
    }
  );

  axios.interceptors.response.use(
    function (res) {
      return res;
    },
    (err) => {
      return Promise.reject(err);
    }
  );

  return axios.get(API_URL + "/quiz-questions?" + sorting , {
    headers: authHeader(),
  });
}

export default {
  loginValidate,
  getMasterEventCard,
  getOtherEventCard,
  getExperiencesList,
  getPeopleList,
  getPeopleById,
  getCollaboratorsList,
  getCollaboratorByEmail,
  getCollaboratorDataByEmail,
  getCollaboratorsById,
  getOtherEventCardByCollabId,
  getAreaDetail,
  getShowcaseList,
  getAreaList,
  getMediaTages,
  getEventSetting,
  getMediaFiles,
  getAreaById,
  getIndustryZoneList,
  getQuestionList,
};
