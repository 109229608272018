import {
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
} from "@material-ui/core";
import React, { useEffect } from "react";
import userService from "../../services/user.service";
import { Col, Modal } from "react-bootstrap";

const QuestionLibraryPopup = (props) => {
  const [filteredBy, setFilteredBy] = React.useState();
  const [seachFilter, setSearchFilter] = React.useState("");
  const [sortedBy, setSortedBy] = React.useState("RECENT");
  const [catFilteredBy, setCatFilteredBy] = React.useState(props.category);
  const [questionList, setQuestionList] = React.useState([]);
  const [tempQuestionList, setTempQuestionList] = React.useState([]);
  const [selectedQuestion, setSelectedQuestion] = React.useState();
  const [selectedQuestionRadio, setselectedQuestionRadio] = React.useState();

  useEffect(() => {
    // API call to get questions
    const fetchQuestions = async () => {
      const response = await userService.getQuestionList();
      setQuestionList(response.data);
      setTempQuestionList(response.data);
    };
    fetchQuestions();
  }, []);

  useEffect(() => {
    let tempList = [...tempQuestionList];
  
    // Apply search filter if searchFilter is not an empty string
    if (seachFilter !== "") {
      tempList = tempList.filter((obj) => {
        const object = obj.question.toLowerCase(); // Ensure you're using the right field for search
        return object.includes(seachFilter.toLowerCase());
      });
    }
  
    // Apply sorting based on 'sortedBy' state
    if (sortedBy !== "RECENT") {
      switch (sortedBy) {
        case "OLDEST":
          tempList.sort((a, b) =>
            new Date(a.created_at) > new Date(b.created_at) ? 1 : -1
          );
          break;
        case "A2Z":
          tempList.sort((a, b) =>
            a.question.toLowerCase() > b.question.toLowerCase() ? 1 : -1
          );
          break;
        case "Z2A":
          tempList.sort((a, b) =>
            a.question.toLowerCase() < b.question.toLowerCase() ? 1 : -1
          );
          break;
        default:
          break;
      }
    }
  
    // Set the updated list after filtering and sorting
    setQuestionList(tempList);
  
  }, [seachFilter, sortedBy]);  // Include questionList as a dependency
    // Include questionList as a dependency
  

  const handleRadioChange = (item) => {
    setselectedQuestionRadio(JSON.parse(item).id);
    setSelectedQuestion(item);
  };

  const handleDone = () => {
    props.setSelectedQuestionMedia(JSON.parse(selectedQuestion));
    props.onHide();
  };

  const handleAddMoreQuestions = () => {
    props.onHide();
    props.setShowCreateQuestionPopup();
  }

  return (
    <Modal
      show={props.show}
      onHide={props.onHide}
      size="xl"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="ad-area-modal upload-assets-popup for_media_library"
    >
      <Modal.Header>
        <div>
          <h4 className="popup_title">Questions Library</h4>
          <p className="selected_assets_caption">
            Manage the questions before adding them to the Questions Library
          </p>
        </div>
        <div className="d-flex align-items-center justify-content-between">
          <div className="selected_assets mr-5 mb-0">
            <Button variant="outlined" color="primary" onClick={handleAddMoreQuestions}>
              Add More Questions
            </Button>
          </div>
          <p className="popup_close_icon" onClick={props.onHide}>
            <img className="img-fluid" src="/images/close.svg" alt="" />
          </p>
        </div>
      </Modal.Header>
      <Modal.Body>
        <div className="default_assets mb-0">
          <Col lg={12} className="p-0 pb-4 d-flex align-items-center">
            <div className="mr-3">
              <FormControl variant="outlined">
                <InputLabel htmlFor="NamePlacard" className="label-caption">
                  Search
                </InputLabel>
                <OutlinedInput
                  id="SearchFilter"
                  type="text"
                  name="search_filter"
                  className="material"
                  value={seachFilter}
                  onChange={(e) => setSearchFilter(e.target.value)}
                />
              </FormControl>
            </div>
            <div className="mr-4">
              <FormControl className="filter_by_control">
                <span className="filter_text_caption">Sort by:</span>
                <span>
                  <Select
                    value={sortedBy}
                    onChange={(e) => setSortedBy(e.target.value)}
                    displayEmpty
                    inputProps={{ "aria-label": "Sorted by" }}
                  >
                    <MenuItem value={"RECENT"}>Most Recent Uploads</MenuItem>
                    <MenuItem value={"OLDEST"}>Oldest Uploads</MenuItem>
                    <MenuItem value={"A2Z"}>
                      Alphabetical Order (A to Z)
                    </MenuItem>
                    <MenuItem value={"Z2A"}>
                      Alphabetical Order (Z to A)
                    </MenuItem>
                  </Select>
                </span>
              </FormControl>
            </div>
          </Col>
          <ul
            className={
              questionList.length <= 4
                ? "media_library_ul"
                : "media_library_ul media_lib_with_height"
            }
          >
            {questionList.length > 0 &&
              questionList.map((item, index) => (
                <li
                  className={
                    selectedQuestionRadio === item.id
                      ? `media_library_item selectedImage`
                      : `media_library_item`
                  }
                  key={item.id}
                  onClick={() => handleRadioChange(JSON.stringify(item))}
                >
                  <div className="material-group question-group pt-1">
                    <FormControl
                      variant="outlined"
                      className="cover_with_button"
                    >
                      <InputLabel
                        htmlFor="questionText"
                        className="label-caption"
                      >
                        Question
                      </InputLabel>
                      <OutlinedInput
                        id="questionText"
                        type="text"
                        multiline
                        rows={5}
                        className="material"
                        defaultValue={item.question}
                        readOnly
                      />
                    </FormControl>
                  </div>
                </li>
              ))}
          </ul>
        </div>
        <div className="modal_footer flex_end_footer">
          <div className="upload_section">
            <Button
              variant="outlined"
              color="primary"
              className="remove_btn mr-2"
              onClick={props.onHide}
            >
              Cancel
            </Button>
            <Button variant="contained" color="primary" onClick={() => handleDone()}>
              Done
            </Button>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default QuestionLibraryPopup;
